'use strict';

window.ranger = window.ranger || {};

/**
 * Handles the single post UI.
 */
ranger.PrettyHand = class {
  /**
   * Initializes the Pretty Hand
   * @constructor
   */
  constructor() {
    // Firebase SDK.
    this.database = firebase.database();
    this.auth = firebase.auth();

    $(document).ready(() => {

    });
  }

  convertToReadable(handHistory) {
    /** Build Parsed Hand **/
        var $ante = '';
        if (handHistory.settings.ante != 0 && handHistory.settings.ante != undefined) {
            $ante = handHistory.settings.ante + ' ante';
        }
        var $heading = '<div class="card-header">' + handHistory.players.length + ' Players '+ handHistory.settings.blind.small + '/' + handHistory.settings.blind.big + ' ' + $ante + '</div>';

        var $preview = $('<div>');

        var showCards = function(hand, preview = false) {
          if (hand != undefined || hand != '') {
              var formattedHand;
              if (preview != false) {
                  if ( hand.match(/c/g) ) {
                  formattedHand = '<div class="card mdl-color--green"><span class="middle">'+hand.replace(/c/g,'')+'</span></div>';
                  //formattedHand += '<span class="suit clubs"></span><span class="middle">'+hand.replace(/c/g,'')+'</span></div>';
                  } else if (hand.match(/h/g)) {
                  formattedHand = '<div class="card mdl-color--red"><span class="middle">'+hand.replace(/h/g, '')+'</span></div>';
                  //formattedHand += '<span class="suit hearts"></span><span class="middle">'+hand.replace(/h/g,'')+'</span></div>';
                  } else if (hand.match(/d/g) ) {
                  formattedHand = '<div class="card mdl-color--blue"><span class="middle">'+hand.replace(/d/g, '')+'</span></div>';
                  //formattedHand += '<span class="suit diamonds"></span><span class="middle">'+hand.replace(/d/g,'')+'</span></div>';
                  } else if (hand.match(/s/g)) {
                  formattedHand = '<div class="card mdl-color--grey"><span class="middle">'+hand.replace(/s/g, '')+'</span></div>';
                  //formattedHand += '<span class="suit spades"></span><span class="middle">'+hand.replace(/s/g,'')+'</span></div>';
                  }
                  return formattedHand;
              } else if (hand != '' || hand != undefined) {
              if ( hand.match(/c/g) ) {
                  formattedHand = '<span class="cards mdl-color--green">' + hand.replace(/c/g, '') + '</span>';
              } else if (hand.match(/h/g)) {
                  formattedHand = '<span class="cards mdl-color--red">' + hand.replace(/h/g, '') + '</span>';
              } else if (hand.match(/d/g) ) {
                  formattedHand = '<span class="cards mdl-color--blue">' + hand.replace(/d/g, '') + '</span>';
              } else if (hand.match(/s/g)) {
                  formattedHand = '<span class="cards mdl-color--black">' + hand.replace(/s/g, '') + '</span>';
              }
               return formattedHand;
              } else {
               return '';
             }
          }
        }

        var convertBB = function(chips) {
            if (chips === undefined || chips == 0 || chips <1) {
                return '';
            }
            return Math.round((parseFloat(chips) / parseFloat(handHistory.settings.blind.big))*100)/100 + 'bb';
        }

        var $container = $("<div>", {class: "card"});
        var $players = $("<div>", {id: "players", class: "list-group list-group-flush"});
        var $player;

        var $playerCount = 0;


        var $preflop = $("<div>", {id: "preflop", class: "list-group list-group-flush"});
        var $preflopPlayer;

        var $flop = $("<div>", {id: "flop", class: "list-group list-group-flush"});
        var $flopActions;
        var $flopCard;

        var $turn = $("<div>", {id: "turn", class: "list-group list-group-flush"});

        if(handHistory.cards.turn[0]) {
          var $turnCard = $("<li class='inline-block'>").html(showCards(handHistory.cards.turn[0].cards[0]));
        }

        var $turnActions = $("<ul>", {id: "turnActions", class: "list-group list-group-flush"});
        var $turnAction;


        var $river = $("<div>", {id: "river", class: "list-group list-group-flush"});
        if(handHistory.cards.river[0]) {
          var $riverCard = $("<li class='inline-block'>").html(showCards(handHistory.cards.river[0].cards[0]));
        }

        var $showdown = $('<div>', {id: 'showdown', class: ''});
        var $showdownActions;

        for (var j=0; j<handHistory.result.showdown.length; j++) {
            $showdownActions = $('<li>', {class: 'list-group-item list-group-flush'});
            if (handHistory.result.showdown[j].username === handHistory.me.username) {
                $showdownActions.addClass('hero');
                console.log(handHistory.result.showdown[j]);
                $showdownActions.html(handHistory.result.showdown[j].username + ' shows ' + showCards(handHistory.result.showdown[j].cards[0]) + '  ' + showCards(handHistory.result.showdown[j].cards[1]) + ' ' + handHistory.result.showdown[j].result + '<br />');
            } else {
                $showdownActions.html(handHistory.result.showdown[j].username + ' shows ' + showCards(handHistory.result.showdown[j].cards[0]) + '  ' + showCards(handHistory.result.showdown[j].cards[1]) + ' ' + handHistory.result.showdown[j].result + '<br />');
            }
            $showdown.append($showdownActions);
        }
        $showdown.prepend('<div class="card-header">Show Down <span class="text-muted">click to reveal</span></div>');

        var $riverActions = $("<ul>", {id: "riverActions", class: "list-group list-group-flush"});
        var $riverAction;

        for(var i = 0; i < handHistory.players.length; i++){
          var $name;
          $player = $("<li>", {class: "list-group-item"});
            if (handHistory.players[i].username === handHistory.me[0].username) {
                handHistory.players[i].username = '<span class="hero">' + handHistory.players[i].username + '</span>';
            }
            if (handHistory.players[i].bounty === 0) {
                var $bounty = '';
            } else {
              var $bounty = ' (' + handHistory.players[i].bounty + ')';
            }
            var $formattedChips = parseFloat(handHistory.players[i].chips).toLocaleString('en');
            var $bigBlinds = Math.round(parseFloat(handHistory.players[i].chips) / parseFloat(handHistory.settings.blind.big));

          $player.html('<span class="inline-block">' + handHistory.players[i].seat + '</span><span class="inline-block big"> ' + handHistory.players[i].username + '</span><span class="inline-block big">' + $bigBlinds + 'bb ' + $bounty + '</span>');
          $players.append($player);
        }

        $preflopPlayer = $("<li>", {class: "list-group-item list-group-flush"});
        var html='';
        for(var j = 0; j < handHistory.actions.preflop.length; j++){
          if (handHistory.actions.preflop[j].type === 'RAISES' || handHistory.actions.preflop[j].type === 'CALLS' || handHistory.actions.preflop[j].type === 'CHECK' || handHistory.actions.preflop[j].type === 'BET') {
              if ($playerCount > 0) {
                  html += $playerCount + ' folds';
                  $playerCount = 0;
              }
              if (handHistory.actions.preflop[j].username === handHistory.me.username) {
                  html += '<span class="hero">' + handHistory.actions.preflop[j].username + ' ' + handHistory.actions.preflop[j].type + ' <span class="amt">' + (convertBB(handHistory.actions.preflop[j].total) || '') + '</span></span>';
              } else {
                  html += handHistory.actions.preflop[j].username + ' ' + handHistory.actions.preflop[j].type + ' <span class="amt">' + (convertBB(handHistory.actions.preflop[j].total) || '') + '</span>';
              }
              if (j < handHistory.actions.preflop.length - 1 && handHistory.actions.preflop[j].type != 'FOLD') {
                html += ', ';
              }

          } else  if (handHistory.actions.preflop[j].type === 'POST ANTE') {

          }

        }
        $preflopPlayer.html(html);
        $preflop.append($preflopPlayer);

        if(typeof handHistory.cards.flop[0] != 'undefined') {
          for(var j = 0; j < handHistory.cards.flop.length; j++){
            $flopCard = $("<li>", {class: "inline-block"});
            $flopCard.html(showCards(handHistory.cards.flop[0].cards[j]));
            //$flop.append($flopCard);
          }

          $flopActions = $("<li>", {class: "list-group-item list-group-flush"});
          var html = '';
          for(var j = 0; j < handHistory.actions.flop.length; j++){

            if(handHistory.actions.flop[j].username === handHistory.me.username) {
                html += '<span class="hero">' + handHistory.actions.flop[j].username + ' ' + handHistory.actions.flop[j].type + ' <span class="amt">' + (convertBB(handHistory.actions.flop[j].total) || '') + '</span></span>';
            } else {
                html += handHistory.actions.flop[j].username + ' ' + handHistory.actions.flop[j].type + ' <span class="amt">' + (convertBB(handHistory.actions.flop[j].total) || '') + '</span>';
            }
            if (handHistory.actions.flop[j].type === 'FOLD') {
                $playerCount++;
            }
            if (j < handHistory.actions.flop.length - 1 && handHistory.actions.flop[j].type != 'FOLD') {
              html += ', ';
            }

          }
          $flopActions.html(html);
          $flop.append($flopActions);
        }

  if (typeof handHistory.cards.turn != 'undefined') {
        $turnAction = $("<li>", {class: "list-group-item list-group-flush"});
        var html = '';
        for(var j = 0; j < handHistory.actions.turn.length; j++){
            if(handHistory.actions.turn[j].username === handHistory.me.username) {
              html = '<span class="hero">' + handHistory.actions.turn[j].username + ' ' + handHistory.actions.turn[j].type + ' <span class="amt">' + (convertBB(handHistory.actions.turn[j].total) || '') + '</span></span>';
            } else {
              html += handHistory.actions.turn[j].username + ' ' + handHistory.actions.turn[j].type + ' <span class="amt">' + (convertBB(handHistory.actions.turn[j].total) || '') + '</span>';
            }
            if (handHistory.actions.turn[j].type === 'FOLD') {
                $playerCount++;
            }
            if (j < handHistory.actions.turn.length - 1 && handHistory.actions.turn[j].type != 'FOLD') {
              html += ', ';
            }
          $turnAction.html(html);
          $turn.append($turnAction);
        }
      }

  if (typeof handHistory.cards.river != 'undefined') {
          $riverAction = $("<li>", {class: "list-group-item list-group-flush"});
          var html='';
         for(var j = 0; j < handHistory.actions.river.length; j++){
          if(handHistory.actions.river[j].username === handHistory.me.username) {
              html += '<span class="hero">' + handHistory.actions.river[j].username + ' ' + handHistory.actions.river[j].type + ' <span class="amt">' + (convertBB(handHistory.actions.river[j].total) || '') + '</span></span>';
          } else {
              html += handHistory.actions.river[j].username + ' ' + handHistory.actions.river[j].type + ' <span class="amt">' + (convertBB(handHistory.actions.river[j].total) || '') + '</span>';
          }
          if (j < handHistory.actions.river.length - 1 && handHistory.actions.river[j].type != 'FOLD') {
            html += ', ';
          }
          $riverAction.html(html);
          $river.append($riverAction);
        }
      }
        //$preflop.prepend('<li class="list-group-item list-group-flush"> Dealt to '+handHistory.me[0].username + ' ' +showCards(handHistory.me[0].cards[0]) + '  ' + showCards(handHistory.me[0].cards[1]) +'</li>');
        $preflop.prepend('<div class="card-header">'+handHistory.me[0].username + ' ' +showCards(handHistory.me[0].cards[0]) + '  ' + showCards(handHistory.me[0].cards[1]) + '</div>');

        //$players.prepend();
        //$players.prepend($blinds);
  if (typeof handHistory.cards.flop[0] != 'undefined') {
        //$flop.prepend('<span class="streetInfo"><i class="material-icons">person</i>'+(handHistory.players.length - $playerCount)+ '</span><span class="mdl-color-text--light-green">' + convertBB(handHistory.money.flopPot) + '</span> ' + showCards(handHistory.cards.flop[0].cards[0]) + showCards(handHistory.cards.flop[0].cards[1]) + showCards(handHistory.cards.flop[0].cards[2]) + '<br />');
        $flop.prepend('<div class="card-header">' + showCards(handHistory.cards.flop[0].cards[0]) + showCards(handHistory.cards.flop[0].cards[1]) + showCards(handHistory.cards.flop[0].cards[2]) + '<span style="float: right">'+convertBB(handHistory.money.preflop) + '</span></div>');
  }

  if (typeof handHistory.cards.turn[0] != 'undefined') {
        //$turn.prepend('<span class="streetInfo"><i class="material-icons">person</i>'+(handHistory.players.length - $playerCount)+ '</span><span class="mdl-color-text--light-green">' + convertBB(handHistory.money.turnPot) + '</span> ' + showCards(handHistory.cards.flop[0].cards[0]) + showCards(handHistory.cards.flop[0].cards[1]) + showCards(handHistory.cards.flop[0].cards[2]) + showCards(handHistory.cards.turn[0].cards[0]) + '<br />')
        $turn.prepend('<div class="card-header">'+showCards(handHistory.cards.flop[0].cards[0]) + showCards(handHistory.cards.flop[0].cards[1]) + showCards(handHistory.cards.flop[0].cards[2]) + showCards(handHistory.cards.turn[0].cards[0])+ '<span style="float: right">'+convertBB(handHistory.money.flop + handHistory.money.preflop) + '</span></div>');
  }

  if (typeof handHistory.cards.river[0] != 'undefined') {
        //$river.prepend('<span class="streetInfo"><i class="material-icons">person</i>'+(handHistory.players.length - $playerCount)+ '</span><span class="mdl-color-text--light-green">' + convertBB(handHistory.money.riverPot) + '</span> ' + showCards(handHistory.cards.flop[0].cards[0]) + showCards(handHistory.cards.flop[0].cards[1]) + showCards(handHistory.cards.flop[0].cards[2]) + showCards(handHistory.cards.turn[0].cards[0]) + showCards(handHistory.cards.river[0].cards[0]) + '<br />')
        $river.prepend('<div class="card-header">' + showCards(handHistory.cards.flop[0].cards[0]) + showCards(handHistory.cards.flop[0].cards[1]) + showCards(handHistory.cards.flop[0].cards[2]) + showCards(handHistory.cards.turn[0].cards[0]) + showCards(handHistory.cards.river[0].cards[0]) + '<span style="float: right">'+convertBB(handHistory.money.flop + handHistory.money.preflop + handHistory.money.turn) + '</span></div>');
      }

        $container.append($heading);
        $container.append($players);

        $container.append($preflop);

        $container.append($flop);


        $container.append($turn);
        $container.append($river);
        $container.append($showdown);

        if (typeof handHistory.cards.flop[0] != 'undefined') {
        for (var j = 0; j < handHistory.cards.flop[0].cards.length; j++) {
            $preview.append('<span class="flop-block">' + showCards(handHistory.cards.flop[0].cards[j], true) + '</span>');
        }
        }
        if (typeof handHistory.cards.turn[0] != 'undefined'){
            $preview.append('<span class="flop-block">' + showCards(handHistory.cards.turn[0].cards[0], true) + '</span>');
        }
        if (typeof handHistory.cards.river[0] != 'undefined') {
            $preview.append('<span class="flop-block">' + showCards(handHistory.cards.river[0].cards[0], true) + '</span>');
        }
        $preview.append('<hr />');
        $preview.append('<div class="heroHolder">' + showCards(handHistory.me[0].cards[0], true) + ' ' + showCards(handHistory.me[0].cards[1], true) + '<div class="starsBottom"><span>'+handHistory.me[0].username+'</span><span></span></div></div><br />');


        $("#handInfo").append($container);
        $("#handPreview").append($preview);

  }

}
ranger.prettyHand = new ranger.PrettyHand();
